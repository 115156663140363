<template>
  <div class="manage-container">
    <el-card class="search-header">
      <el-form
        :inline="true"
        ref="SearchFromRef"
        :model="searchForm"
        size="small"
      >
        <div class="search-form">
          <div>
            <!-- <el-form-item label="关键字搜索:" prop="keyword">
              <el-input
                v-model="searchForm.keyword"
                placeholder="请输入关键字"
              />
            </el-form-item> -->
          </div>
          <div>
            <el-form-item>
              <el-button @click="resetSearch">刷新</el-button>
              <!-- <el-button type="primary">查询</el-button> -->
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card>
      <div class="table-header">
        <el-button
          type="primary"
          size="small"
          @click="handleDownload"
          :loading="exportLoading"
        >
          导出
        </el-button>
      </div>
      <el-table
        :data="allAccounts"
        v-loading="loading"
        border
        style="width: 100%"
      >
        <el-table-column label="用户名" prop="username" width="120">
        </el-table-column>
        <el-table-column label="手机号" prop="phone" width="120">
        </el-table-column>
        <el-table-column label="查询时间" prop="create_at" width="180">
          <template #default="scope">
            {{ formatDate(scope.row.create_at) }}
          </template>
        </el-table-column>
        <el-table-column label="配件编码" prop="code" width="120">
        </el-table-column>
        <el-table-column
          label="替换号码"
          prop="new_material_number"
          width="120"
        >
        </el-table-column>
        <el-table-column label="零售价" prop="retail_price" width="120">
          <template #default="scope">
            {{ formatPrice(scope.row.retail_price) }}
          </template>
        </el-table-column>
        <el-table-column label="蓝白光束价" prop="blue_white_price" width="120">
          <template #default="scope">
            {{ formatPrice(scope.row.blue_white_price) }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="area in areasList"
          :key="area.value"
          :label="area.name"
          :prop="area.value"
          width="100"
        >
          <template #default="scope">
            {{ formatCount(area.value, scope.row[area.value]) }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { searchList, exportExcel } from '@/api/search-history'
import { handleExport, formatDate, formatPrice } from '@/utils'

// 数据相关
const allAccounts = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)

const areasList = [
  {
    value: 'beijing',
    name: '北京仓'
  },
  {
    value: 'shanghai',
    name: '上海仓'
  },
  {
    value: 'foshan',
    name: '佛山仓'
  },
  {
    value: 'chengdu',
    name: '成都仓'
  },
  {
    value: 'shenyang',
    name: '沈阳仓'
  },
  {
    value: 'hefei',
    name: '合肥仓'
  },
  {
    value: 'ningbo',
    name: '宁波仓'
  }
  // {
  //   value: 'foreign',
  //   name: '外地仓'
  // },
  // {
  //   value: 'germany',
  //   name: '德国仓'
  // }
]

const formatCount = (key, val) => {
  val = +val
  if (key === 'foreign') {
    // 外地
    if (val === 0) {
      return '库存不足'
    } else if (val === 1) {
      return '库存紧张'
    } else {
      return '库存充足'
    }
  } else if (key === 'germany') {
    // 德国
    if (val === 0) {
      return '库存不足'
    } else if (val <= 2) {
      return '库存紧张'
    } else {
      return '库存充足'
    }
  } else {
    // 其余
    if (val === 0) {
      return '库存不足'
    } else if (val === 1) {
      return '库存紧张'
    } else {
      return '库存充足'
    }
  }
}

// 获取数据的方法
const loading = ref(false)
const getSearchtList = async () => {
  loading.value = true
  const result = await searchList({
    page: page.value,
    size: size.value
  })
  loading.value = false
  allAccounts.value = result.data.list
  total.value = result.data.total || 0
}

// 处理数据不重新加载的问题
onActivated(getSearchtList)

// 搜索相关
const SearchFromRef = ref(null)
const searchForm = reactive({
  keyword: ''
})

// 重置搜索
const resetSearch = () => {
  SearchFromRef.value.resetFields()
  getSearchtList()
}

/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getSearchtList()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getSearchtList()
}

const exportLoading = ref(false)
const handleDownload = () => {
  exportLoading.value = true
  exportExcel()
    .then((res) => {
      handleExport(res, '历史查询记录')
    })
    .finally(() => {
      exportLoading.value = false
    })
}
</script>

<style lang="scss" scoped>
.manage-container {
  .search-header {
    margin-bottom: 20px;

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-form {
      display: flex;
      justify-content: space-between;
    }
  }

  .table-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
