import request from '@/utils/request'
import axios from 'axios'
import store from '@/store'

/**
 * 历史查询记录
 */
export const searchList = (params) => {
  return request({
    url: '/search/list',
    method: 'GET',
    params
  })
}

/**
 * 导出
 */

export const exportExcel = () => {
  return axios.post(process.env.VUE_APP_BASE_API + '/export/excel', null, {
    headers: {
      'Content-Type': 'application/json',
      token: store.getters.token
    },
    responseType: 'arraybuffer'
  })
}
